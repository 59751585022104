import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClassesRoutingModule } from './classes-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ListclassesComponent} from './listclass/listclasses.component'
import { DeleteDialogComponent } from './listclass/dialogs/delete/delete.component';
import { FormDialogComponent } from './listclass/dialogs/form-dialog/form-dialog.component';
import { StudentService } from 'src/app/core/service/student.service';
import { ClassesService } from 'src/app/core/service/classes.service';

import { LoadingBtnModule } from 'src/app/core/components/loading-btn/loading-btn.module';
import { UploadFileComponent } from './listclass/dialogs/upload-file/upload-file.component';
import { MatListModule } from '@angular/material/list';
import { NgxFileDropComponent, NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
  declarations: [
    ListclassesComponent,
    DeleteDialogComponent,
    FormDialogComponent,
    UploadFileComponent,
    
  ],
  imports: [
    CommonModule,
    ClassesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MaterialFileInputModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    LoadingBtnModule,
    MatListModule,
    NgxFileDropModule
  ],
  providers: [StudentService, ClassesService],
})
export class ClassesModule { }
