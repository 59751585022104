import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import {ListlessoncsComponent} from './listlessons/listlessons.component' ;

const routes: Routes = [{
  path: '',
  redirectTo: 'calendar',
  pathMatch: 'full',
},
{
  path: 'calendar',
  component: CalendarComponent,
},
{
  path: 'list',
  component: ListlessoncsComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LessonsRoutingModule { }
