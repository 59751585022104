import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { Student } from "../../../../../core/models/student.model";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { formatDate } from "@angular/common";
import { StudentService } from "../../../../../core/service/student.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ClassesService } from "src/app/core/service/classes.service";
import { map } from "rxjs/operators";
import { RegisterviewComponent } from "../registerview/registerview.component";
import { AutofillMonitor } from "@angular/cdk/text-field";

@Component({
  selector: "app-form-dialog",
  templateUrl: "./form-dialog.component.html",
  styleUrls: ["./form-dialog.component.sass"],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "it-IT" }],
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  libraryForm: FormGroup;
  library: Student;
  classValue = 0;
  loading = false;
  errors: any = {};
  classesList = this.classes.Classes.pipe(
    map((data) => {
      return data.data;
    })
  );
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public libraryService: StudentService,
    private fb: FormBuilder,
    public classes: ClassesService,
    public dialog: MatDialog
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === "edit") {
      this.dialogTitle = data.student.name;
      this.library = data.student;
    } else {
      this.dialogTitle = "Nuovo studente";
      this.library = new Student({});
    }
    this.libraryForm = this.createContactForm();
  }
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
      ? "Not a valid email"
      : "";
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      id: [this.library.id],
      name: [this.library.name, [Validators.required]],
      email: [this.library.email, [Validators.required, Validators.email]],
      phone: [this.library.phone, [Validators.required]],
      address: [this.library.address, [Validators.required]],
      birthdate: [this.library.birthdate, [Validators.required]],
      class: [this.library.class, [Validators.required]],
      parent: this.fb.group({
        name: [this.library.parent.name],
        email: [this.library.parent.email],
        phone: [this.library.parent.phone],
      }),
    });
  }
  submit() {
    // emppty stuff
  }
  openRegister() {
    const dialogRef = this.dialog.open(RegisterviewComponent, {
      width: "50%",
      data: {
        student: this.library,
      },
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  hasErrors() {
    return Object.keys(this.errors).length;
  }

  public confirmAdd(): void {
    this.loading = true;
    this.libraryService.addStudent(this.libraryForm.getRawValue()).subscribe(
      (data) => {
        this.loading = false;
        if (data.success) {
          this.dialogRef.close(1);
        } else {
          //this.dialogRef.close(-1);
          this.errors = data.data;
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        //this.dialogRef.close(-1);
        this.errors = { connection: [err] };
        this.loading = false;
      }
    );
  }

  public shareText() {
    const text = `Scuola Virtuale - Registro scolastico ${this.library.name} - 
    https://amministrazione.prontodiploma.it/#/registroalunno/${this.library.parentcode}
    Usa la password ${this.library.parent.password}`;
    return encodeURIComponent(text);
  }
}
