import { UploadFileLessonsComponent } from "./calendar/dialogs/uploadFileLessons/uploadFileLessons.component";
import { UploadFileComponent } from "./../classes/listclass/dialogs/upload-file/upload-file.component";
import { VacationsComponent } from "./calendar/dialogs/vacations/vacations.component";

import { MatNativeDateModule } from "@angular/material/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LessonsRoutingModule } from "./lessons-routing.module";
import { CalendarComponent } from "./calendar/calendar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ListlessoncsComponent } from "./listlessons/listlessons.component";
import { FormDialogComponent } from "./listlessons/dialogs/form-dialog/form-dialog.component";
import { DeleteDialogComponent } from "./listlessons/dialogs/delete/delete.component";
import { LessonsService } from "src/app/core/service/lessons.service";
import { RegisterService } from "src/app/core/service/register.service";
import { FullCalendarModule } from "@fullcalendar/angular";

import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { RegisterviewComponent } from "./listlessons/dialogs/registerview/registerview.component";

import { LoadingBtnModule } from "../../core/components/loading-btn/loading-btn.module";

import { NgxFileDropModule } from "ngx-file-drop";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    CalendarComponent,
    ListlessoncsComponent,
    FormDialogComponent,
    DeleteDialogComponent,
    RegisterviewComponent,
    VacationsComponent,
    UploadFileLessonsComponent,
  ],
  imports: [
    CommonModule,
    LessonsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MaterialFileInputModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    FullCalendarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    LoadingBtnModule,
    MatIconModule,
    NgxFileDropModule,
  ],
  providers: [
    LessonsService,
    RegisterService,
    { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LessonsModule {}
