<div class="container">
    <h3 mat-dialog-title>Sei sicuro che vuoi eliminare la classe?</h3>
    <div mat-dialog-content>
        <ul class="clearfix">
            <li>
                <p><span class="font-weight-bold"> Nome: </span>{{data.name}}</p>
            </li>
            <li>
                <p><span class="font-weight-bold"> Anno scolastico: </span>{{ data.year }}</p>
            </li>
            <li>
                <p><span class="font-weight-bold"> Corso: </span>{{ data.coursename }}</p>
            </li>
          
        </ul>
    </div>
    <div mat-dialog-actions class="mb-1">
        <button mat-flat-button color="warn" [mat-dialog-close]="1" (click)="confirmDelete()">
            Elimina
        </button>
        <button mat-flat-button (click)="onNoClick()" tabindex="-1">Annulla</button>
    </div>
</div>