import { UploadFileLessonsComponent } from "./dialogs/uploadFileLessons/uploadFileLessons.component";
import { VacationsComponent } from "./dialogs/vacations/vacations.component";
import { Component, ViewChild, OnInit } from "@angular/core";
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  Calendar,
  FullCalendarComponent,
} from "@fullcalendar/angular";
import { EventInput } from "@fullcalendar/angular";

import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import itLocale from "@fullcalendar/core/locales/it";

import { MatSnackBar } from "@angular/material/snack-bar";

import { MatCheckboxChange } from "@angular/material/checkbox";
import { LessonsService } from "src/app/core/service/lessons.service";
import { FormDialogComponent } from "../listlessons/dialogs/form-dialog/form-dialog.component";
import { Lesson } from "src/app/core/models/lesson.model";

import * as moment from "moment-timezone";
import { ClassesService } from "src/app/core/service/classes.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.sass"],
})
export class CalendarComponent implements OnInit {
  @ViewChild("calendarEl", { static: false }) calendarEl;
  @ViewChild("calendarEl") calendarComponent: FullCalendarComponent;
  calendar: Lesson | null;

  public addCusForm: FormGroup;
  dialogTitle: string;
  filterOptions = "All";
  calendarData: any;
  filterItems: number[] = [];

  calendarEvents: EventInput[];
  tempEvents: EventInput[];

  public filters: any = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public calendarService: LessonsService,
    private snackBar: MatSnackBar,
    public classesService: ClassesService
  ) {
    this.dialogTitle = "Aggiungi nuova lezione";
    this.calendar = new Lesson({});
    this.addCusForm = this.createCalendarForm(this.calendar);
    this.classesService.Classes.subscribe((data) => {
      this.filters = [];
      this.filterItems = [];
      for (let item of data.data) {
        this.filters.push({ name: item.name, value: item.id, checked: true });
        this.filterItems.push(item.id);
      }
    });
  }

  public ngOnInit(): void {
    this.calendarEvents = [];

    this.calendarOptions.initialEvents = this.calendarEvents;
    /*setTimeout(()=>{
      this.loadEvents()

    }
    ,100) ;*/

    //this.calendarService.getLessonsByDate().subscribe() ;
  }

  upload_lessons() {
    const dialogRef = this.dialog.open(UploadFileLessonsComponent, {
      data: {
        calendar: this.calendar,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadEvents();
    });
  }

  loadEvents() {
    const start = moment(
      this.calendarComponent.getApi().currentData.dateProfile.activeRange.start
    ).format("YYYY-MM-DD");
    const end = moment(
      this.calendarComponent.getApi().currentData.dateProfile.activeRange.end
    ).format("YYYY-MM-DD");
    this.calendarService.getLessonsByDate(start, end).subscribe((data: any) => {
      this.calendarEvents = [];
      this.tempEvents = data.data;
      //console.log('LEZIONI => ', data.data);
      for (let lesson of data.data) {
        const classes = lesson.classes.map((c) => {
          return c.name;
        });
        const groupId = lesson.classes.map((c) => {
          return c.id;
        });
        let noteClasse = lesson.note ? lesson.note : "-";
        this.calendarEvents.push({
          id: lesson.id,
          title:
            lesson.subjectname +
            " " +
            lesson.teachername +
            " - Note: " +
            noteClasse +
            " Classi: " +
            classes,
          start: new Date(lesson.start),
          end: new Date(lesson.end),
          className: "fc-event-success",
          groupId: groupId,
          details:
            "Lezione di " +
            lesson.subjectname +
            " tenuta da " +
            lesson.teacherName +
            " per " +
            (classes.length > 1 ? "le classi " : "la classe ") +
            classes.join(", "),
        });
      }

      this.calendarOptions.events = this.calendarEvents;
      this.filterEvent(this.filterItems);
      //this.handleEvents(this.calendarOptions.events) ;
    });
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
    },
    initialView: "dayGridMonth",
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    datesSet: this.handleDataChange.bind(this),
    eventChange: this.handleEventChange.bind(this),
    events: [],
    locales: [itLocale],
    locale: "it",
  };

  handleDataChange(data) {
    this.loadEvents();
  }

  vacation_day() {
    const dialogRef = this.dialog.open(VacationsComponent, {
      data: {
        calendar: this.calendar,
      },
    });
  }

  handleEventChange(data) {
    let lesson: any = this.tempEvents.filter((l) => l.id == data.event.id)[0];
    if (lesson.classes.length && lesson.classes[0].id) {
      lesson.classes = lesson.classes.map((l) => l.id);
    }
    lesson.start = data.event.start;
    lesson.end = data.event.end;
    this.calendarService.addLesson(lesson).subscribe((data) => {
      this.loadEvents();
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.addNewEvent(selectInfo.start);
  }

  addNewEvent(startday: string | Date = "") {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        calendar: this.calendar,
        action: "add",
        startday: startday,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.calendarData = this.calendarService.getDialogData();

        this.loadEvents();
        this.showNotification(
          "snackbar-success",
          "Le lezioni sono state create con successo",
          "bottom",
          "center"
        );
      }
    });
  }

  changeCategory(event: MatCheckboxChange, filter) {
    if (event.checked) {
      this.filterItems.push(filter.value);
    } else {
      this.filterItems.splice(this.filterItems.indexOf(filter.value), 1);
    }
    this.filterEvent(this.filterItems);
  }

  filterEvent(element) {
    const list = this.calendarEvents.filter((x) => {
      for (let el of element) if (x.groupId.indexOf(el) != -1) return true;
      return false;
    });

    this.calendarOptions.events = list;
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.eventClick(clickInfo);
  }

  downloadCSV() {
    window.open(environment.apiUrl + "/api/lessons/csv", "_blank");
  }

  eventClick(row) {
    let calendarData: any;
    for (let ev of this.tempEvents) {
      if (parseInt(ev.id, 10) == parseInt(row.event.id, 10)) {
        calendarData = ev;
      }
    }

    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        lesson: calendarData,
        action: "edit",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "submit") {
        this.calendarData = this.calendarService.getDialogData();
        this.calendarEvents.forEach(function (element, index) {
          if (this.calendarData.id === element.id) {
            this.editEvent(index, this.calendarData);
          }
        }, this);

        this.showNotification(
          "black",
          "Edit Record Successfully...!!!",
          "bottom",
          "center"
        );
        this.addCusForm.reset();
      } else if (result === "delete") {
        this.calendarData = this.calendarService.getDialogData();
        this.calendarEvents.forEach(function (element, index) {
          if (this.calendarData.id === element.id) {
            row.event.remove();
          }
        }, this);

        this.showNotification(
          "snackbar-danger",
          "Delete Record Successfully...!!!",
          "bottom",
          "center"
        );
      }
      this.loadEvents();
    });
  }

  editEvent(eventIndex, calendarData) {
    const calendarEvents = this.calendarEvents.slice();
    const singleEvent = Object.assign({}, calendarEvents[eventIndex]);
    singleEvent.id = calendarData.id;
    singleEvent.title = calendarData.title;
    singleEvent.start = calendarData.startDate;
    singleEvent.end = calendarData.endDate;
    singleEvent.className = this.getClassNameValue(calendarData.category);
    singleEvent.groupId = calendarData.category;
    singleEvent.details = calendarData.details;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array

    this.calendarOptions.events = calendarEvents;
  }

  handleEvents(events: EventApi[]) {
    // this.currentEvents = events;
  }

  createCalendarForm(calendar): FormGroup {
    return this.fb.group({
      id: [calendar.id],
      title: [
        calendar.title,
        [Validators.required, Validators.pattern("[a-zA-Z]+([a-zA-Z ]+)*")],
      ],
      category: [calendar.category],
      startDate: [calendar.startDate, [Validators.required]],
      endDate: [calendar.endDate, [Validators.required]],
      details: [
        calendar.details,
        [Validators.required, Validators.pattern("[a-zA-Z]+([a-zA-Z ]+)*")],
      ],
    });
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  getClassNameValue(category) {
    let className: string;

    if (category === "work") className = "fc-event-success";
    else if (category === "personal") className = "fc-event-warning";
    else if (category === "important") className = "fc-event-primary";
    else if (category === "travel") className = "fc-event-danger";
    else if (category === "friends") className = "fc-event-info";

    return "fc-event-success";
  }
}
