import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Course, CourseRespose } from '../models/course.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CoursesService {
  private readonly API_URL = environment.apiUrl + '/api/courses';
  isTblLoading = true;
  dataChange: BehaviorSubject<Course[]> = new BehaviorSubject<Course[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Course[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllCourses(): void {
    this.httpClient.get<CourseRespose>(this.API_URL+"/all").subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  get Courses(){
    return this.httpClient.get<CourseRespose>(this.API_URL+"/all");
  }
  addSubjects(courses , subjects:number[]){
    return this.httpClient.post(this.API_URL+"/subjects",{subjects:subjects,course:courses})
  }
  addCourse(course: Course) {
    this.dialogData = course;
    if( course.id == 0 )
      delete course.id ;
     return this.httpClient.post(this.API_URL+"/save", course).pipe(map((data:any)=>{
       if( data.success){
        data.data.subjects = course.subjects ;
        this.dialogData = data.data ;
       }
       return data ;
     })) ;
  }
  
  deleteCourse(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(map((data:any)=>{
      console.log(data) ;
      return data ;
    })) ;
  }
}
