import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Lesson, LessonRespose } from "../models/lesson.model";

@Injectable()
export class LessonsService {
  private readonly API_URL = environment.apiUrl + "/api/lessons";
  isTblLoading = true;
  dataChange: BehaviorSubject<Lesson[]> = new BehaviorSubject<Lesson[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Lesson[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllLessons(start, end): void {
    this.httpClient
      .get<LessonRespose>(this.API_URL + "/all/" + start + "/" + end)
      .subscribe(
        (data) => {
          this.isTblLoading = false;
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          this.isTblLoading = false;
          console.log(error.name + " " + error.message);
        }
      );
  }

  getLessonsByDate(start, end) {
    return this.httpClient.get<LessonRespose>(
      this.API_URL + "/all/" + start + "/" + end
    );
  }

  sendNotification(id) {
    return this.httpClient.get(this.API_URL + "/notification/" + id);
  }

  addLesson(lesson: Lesson) {
    if (lesson.id == 0) {
      delete lesson.id;
    }
    return this.httpClient.post(this.API_URL + "/save", lesson).pipe(
      map((data: any) => {
        if (data.success) {
          data.data.repeat = lesson.repeat;
        }
        this.dialogData = data.data;
        return data;
      })
    );
  }

  lesson(id: number) {
    return this.httpClient.get(this.API_URL + "/lesson/" + id);
  }

  getVacationsDays() {
    return this.httpClient.get(this.API_URL + "/vacations");
  }

  setVacationsDays(days: Date[]) {
    return this.httpClient.post(this.API_URL + "/vacations", { days });
  }

  saveRecorderLesson(file, lesson) {
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("lesson", lesson);

    return this.httpClient.post(
      this.API_URL + "/recorderteacherLesson",
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }


  getLessonRecorderFile(lesson){
    return this.httpClient.get(this.API_URL + "/recorder/lesson/" + lesson) ;
  }


  deleteLesson(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(
      map((data: any) => {
        console.log(data);
        return data;
      })
    );
  }
  uploadFileLessons(file, from, to) {
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("from", from);
    formData.append("end", to);

    return this.httpClient.post(this.API_URL + "/uploadfet", formData, {
      reportProgress: true,
      observe: "events",
    });
  }
}
