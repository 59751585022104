<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">
            {{ dialogTitle }}
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content style="overflow-x:hidden ;">
    <h4>File condivisi</h4>

    <mat-table *ngIf="classData" #table [dataSource]="dataSource" matSort class="mat-cell">
      <!-- teacher Column -->

      <ng-container matColumnDef="teacher">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Professore
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ getTeacheNameById(row.teacher) }}
        </mat-cell>
      </ng-container>
      <!-- subject Column -->

      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Materia</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ getSubjectNameById(row.subject) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a href="/{{row.path}}" target="_blank">{{ row.name }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="note">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Note</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getNote(row)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Data inserimento</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ getDate(row.created_at) | date: "dd-MM-yyyy" }}</mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
      <mat-row *matRowDef="let row; columns: displayedColumns" [style.cursor]="'pointer'" matRipple>
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Visualizza gli altri file">
    </mat-paginator>
    <h4>Carica altri file</h4>
    <ngx-file-drop *ngIf="classData" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" >
        <div>
          <div style="width: 100%;" *ngIf="tmp.progress>0">
            <div [style.width]="tmp.progess+'%'" style="background-color: green;height:20px">{{tmp.progess}} %</div>
          </div>
          <p>Trascina i file qua dentro il box oppure <button mat-raised-button type="button" (click)="openFileSelector()">
            Carica file
          </button></p>
          <ul style="display: inline; "><li style="margin-bottom: 20px;">Stai caricando <span *ngFor="let item of fileToLoads">{{item.name}}, </span> </li></ul>
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Professore</mat-label>
            <mat-select [(ngModel)]="teacher">
              <mat-option *ngFor="let c of allTeachers" [value]="c.id">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Studente</mat-label>
            <mat-select [(ngModel)]="student">
              <mat-option  value="">
                TUTTI
              </mat-option>
              <mat-option *ngFor="let c of classData.data.students" [value]="c.id">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Materia</mat-label>
            <mat-select [(ngModel)]="subject">
              <mat-option *ngFor="let c of classData.data.subjects" [value]="c.id">
                {{ c.name }} ({{c.description}})
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Classi</mat-label>
            <mat-select [(ngModel)]="seletectedClass" multiple>
              <mat-option *ngFor="let c of allClasses" [value]="c.id">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <div>
            <button mat-raised-button type="button" (click)="caricaSingoloFile()">
              Invia a studente
            </button>
          </div>
        </div>

      </ng-template>
    </ngx-file-drop>
    <div style="height: 50px;"></div>
  </div>
</div>