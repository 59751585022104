import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Scuola Virtuale',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.HOME.TEXT',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [

    ],
  },
  // Teacher Modules

  {
    path: '/teacher/dashboard',
    title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },

  // Student Modules

  {
    path: '/student/dashboard',
    title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },

  // Common Module

  {
    path: '',
    title: 'Authentication',
    moduleName: 'authentication',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin-fake'],
    submenu: [
      {
        path: '/authentication/signin',
        title: 'Sign In',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/signup',
        title: 'Sign Up',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/forgot-password',
        title: 'Forgot Password',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/locked',
        title: 'Locked',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/page404',
        title: '404 - Not Found',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/authentication/page500',
        title: '500 - Server Error',
        moduleName: 'authentication',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Extra Pages',
    moduleName: 'extra-pages',
    iconType: 'material-icons-two-tone',
    icon: 'description',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin-fake'],
    submenu: [
      {
        path: '/extra-pages/blank',
        title: 'Blank Page',
        moduleName: 'extra-pages',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '/admin/courses',
    title: 'Corsi',
    moduleName: 'courses',
    iconType: 'material-icons-two-tone',
    icon: 'school',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
    ],
  },
  {
    path: '/admin/subjects',
    title: 'Materie',
    moduleName: 'subjects',
    iconType: 'material-icons-two-tone',
    icon: 'book',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
    ],
  },
  {
    path: '/admin/teachers',
    title: 'Insegnanti',
    moduleName: 'teachers',
    iconType: 'material-icons-two-tone',
    icon: 'person',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
    ],
  },
  {
    path: '/admin/students',
    title: 'Studenti',
    moduleName: 'multilevel',
    iconType: 'material-icons-two-tone',
    icon: 'nature_people',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
    ],
  },
  {
    path: '/admin/classes',
    title: 'Classi',
    moduleName: 'students',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
    ],
  },
  {
    path: '',
    title: 'Lezioni',
    moduleName: 'lessons',
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['a'],
    submenu: [
      {
        path: '/admin/lessons/calendar',
        title: 'Calendario',
        moduleName: 'calendar',
        iconType: '',
        icon: 'date_range',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['a'],
        submenu: [],
      },
      {
        path: '/admin/lessons/list',
        title: 'Lista',
        moduleName: 'authentication',
        iconType: '',
        icon: 'assigment',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['a'],
        submenu: [],
      },
    ],
  },
  {
    path: '/teacher/dashboard',
    title: 'MENUITEMS.HOME.TEXT',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['t'],
    submenu: [

    ],
  },

  {
    path: '/teacher/lessons/calendar',
    title: 'Lezioni',
    moduleName: 'lessons',
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['t'],
    submenu : []
  },
  {
    path: '/teacher/students',
    title: 'Studenti',
    moduleName: 'multilevel',
    iconType: 'material-icons-two-tone',
    icon: 'nature_people',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['t'],
    submenu: [
    ],
  },
  {
    path: '/teacher/sharingstudents',
    title: 'File studenti',
    moduleName: 'multilevel',
    iconType: 'material-icons-two-tone',
    icon: 'nature_people',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['t'],
    submenu: [
    ],
  },
  {
    path: '/authentication/signin',
    title: 'Esci',
    moduleName: 'logout',
    iconType: 'material-icons-two-tone',
    icon: 'power_settings_new',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
];
