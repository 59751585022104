import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { Role } from "../models/role";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/login`, {
        email: username,
        password: password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if(!user.data.avatar){
            switch( user.data.role){
              case Role.Admin : 
                user.data.avatar = 'assets/images/user/user1.jpg' ;
                break ;
                case Role.Teacher : 
                user.data.avatar = 'assets/images/user/teacher.jpg' ;
                break ;
                case Role.Student : 
                user.data.avatar = 'assets/images/user/user1.jpg' ;
                break ;
            }
          }
          else{
            user.data.avatar = environment.apiUrl + user.data.avatar ;
          }
          localStorage.setItem("currentUser", JSON.stringify(user.data));
          this.currentUserSubject.next(user.data);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("infoUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  async me() {
    let infoUser:any = localStorage.getItem("infoUser");
    
    if (!infoUser) {
      const data = await this.http
        .get<any>(`${environment.apiUrl}/api/user/me`)
        .toPromise();
      localStorage.setItem("infoUser", JSON.stringify(data.data));
      let u = JSON.parse(localStorage.getItem("currentUser"));
      const user = this.currentUserSubject.value;
      if(user.avatar === null) {
        user.avatar = environment.apiUrl + '/'+ user.avatar;
      }
      u.avatar = user.avatar ;
      localStorage.setItem("currentUser", JSON.stringify(u));
      user.firstName = data.data.name;
      this.currentUserSubject.next(user);
      return data.data;
    }
    infoUser = JSON.parse(infoUser);
    infoUser.avatar = environment.apiUrl + infoUser.avatar;
    return infoUser ;
  }

  public saveAvatar(image) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/user/avatar`, { avatar: image })
      .pipe(map(data => {
        localStorage.setItem("infoUser", JSON.stringify(data.data));
        const user = this.currentUserSubject.value;
        user.avatar = environment.apiUrl + data.data.avatar;
        data.data.avatar = environment.apiUrl + data.data.avatar+"?round="+Math.random();
        user.firstName = data.data.name;
        localStorage.setItem("currentUser", JSON.stringify(user));
        this.currentUserSubject.next(user);
        return data.data ;
      }));
  }
}
