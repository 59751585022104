import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Teacher, TeacherRespose } from '../models/teacher.model';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TeacherService {
  private readonly API_URL = environment.apiUrl + '/api/teachers';
  isTblLoading = true;
  dataChange: BehaviorSubject<Teacher[]> = new BehaviorSubject<Teacher[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Teacher[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  
  get Teachers(){
    return this.httpClient.get<TeacherRespose>(this.API_URL+"/all");
  }
  /** CRUD METHODS */
  getAllTeachers(): void {
    this.httpClient.get<TeacherRespose>(this.API_URL+"/all").subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  getTeachers(){
    return this.httpClient.get<TeacherRespose>(this.API_URL+"/all")
  }



  addTeacher(teacher: Teacher) {
    this.dialogData = teacher;
    if( teacher.id == 0 )
      delete teacher.id ;
     return this.httpClient.post(this.API_URL+"/save", teacher).pipe(map((data:any)=>{
       if( data.success)
        this.dialogData = data.data ;
       return data ;
     })) ;
  }

  getStudentLesson(student){
    return this.httpClient.get(this.API_URL + "/student/" + student)
  }

  getStudentLessons( student){
    return this.httpClient.get(this.API_URL + "/lessons/student/" + student)
  }



  saveFile(file){
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data"
    });
    const formData = new FormData();
    formData.append("file", file, file.name);

    return this.httpClient
      .post(this.API_URL + "/upload", formData, {
        reportProgress: true,
        observe: "events",
      })
      
  }
  
  deleteTeacher(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(map((data:any)=>{
      console.log(data) ;
      return data ;
    })) ;
  }

  getAllFile(){
    return this.httpClient
      .get(this.API_URL + "/files/all" )
  }

  getStudentFile(id){
    return this.httpClient
      .get(this.API_URL + "/files/student/"+id)
  }
  getClassFile(id){
    return this.httpClient
      .get(this.API_URL + "/files/class/"+id )
  }

  associateFilesStudent(ids,student){
    return this.httpClient
      .post(this.API_URL + "/files/student", {ids : ids , student:student})

  }
  associateFilesClass( ids,classid){
    return this.httpClient
      .post(this.API_URL + "/files/class", {ids : ids,"class":classid})
  }

  associateFilesClassesLesson( ids,classes,lesson){
    return this.httpClient
      .post(this.API_URL + "/files/lesson", {ids : ids,"classes":classes , "lesson" : lesson})
  }

  getAllFilesStudents(){
    return this.httpClient.get(this.API_URL+"/files/students") ;
  }
}
