import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { TeacherService } from '../../../../../core/service/teacher.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.sass']
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public courseService: TeacherService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  confirmDelete(): void {
    this.courseService.deleteTeacher(this.data.id).subscribe( data=> {
      if( data.success )
      {
        this.dialogRef.close(1);
      }
      else{
        this.dialogRef.close(-1);
      }
    },
    (err: HttpErrorResponse) => {
      this.dialogRef.close(-1);
    });
  }
}
