import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject, SubjectRespose } from '../models/subject.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class SubjectService {
  private readonly API_URL = environment.apiUrl + '/api/subjects';
  isTblLoading = true;
  dataChange: BehaviorSubject<Subject[]> = new BehaviorSubject<Subject[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Subject[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllSubjects(): void {
    this.httpClient.get<SubjectRespose>(this.API_URL+"/all").subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  get Subjects(){
    return this.httpClient.get<SubjectRespose>(this.API_URL+"/all");
  }


  addCourses(subject , courses:number[]){
    return this.httpClient.post(this.API_URL+"/courses",{subject:subject,courses:courses})
  }

  addSubject(subject: Subject) {
    this.dialogData = subject;
    if( subject.id == 0 )
      delete subject.id ;
     return this.httpClient.post(this.API_URL+"/save", subject).pipe(map((data:any)=>{
       if( data.success){
         data.data.courses = subject.courses ;
        this.dialogData = data.data ;
       }
       return data ;
     })) ;
  }
  
  deleteSubject(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(map((data:any)=>{
      console.log(data) ;
      return data ;
    })) ;
  }
}
