import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { StudentService } from '../../../../../core/service/student.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { LessonsService } from 'src/app/core/service/lessons.service';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.sass']
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public lessonService: LessonsService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  getFormatDate(date){
    return moment(date).format("DD-MM-YYYY HH:mm")
  }

  confirmDelete(): void {
    this.lessonService.deleteLesson(this.data.id).subscribe( data=> {
      if( data.success )
      {
        this.dialogRef.close(1);
      }
      else{
        this.dialogRef.close(-1);
      }
    },
    (err: HttpErrorResponse) => {
      this.dialogRef.close(-1);
    });
  }
}
