import { HttpEventType } from "@angular/common/http";
import { LessonsService } from "src/app/core/service/lessons.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import * as moment from "moment";

@Component({
  selector: "app-uploadFileLessons",
  templateUrl: "./uploadFileLessons.component.html",
  styleUrls: ["./uploadFileLessons.component.sass"],
})
export class UploadFileLessonsComponent implements OnInit {
  fileToLoads = [];


  message = "" ;

  public files: NgxFileDropEntry[] = [];
  public loadingFile: any[] = [];
  tmp = { file: "", progress: 0 };
  from = new Date();
  to = new Date();
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<UploadFileLessonsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public lessonService: LessonsService
  ) {}

  ngOnInit() {}

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.loadingFile = [];
    this.tmp = { file: "", progress: 0 };
    this.fileToLoads = [];
    for (const droppedFile of files) {
      const tmp = { file: droppedFile, progress: 0 };
      this.loadingFile.push(tmp);
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          this.fileToLoads.push(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
      break;
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  public caricaSingoloFile() {
   
    for (const f of this.fileToLoads) {
      this.tmp = { file: f, progress: 0 };
      this.loading = true ;
      this.lessonService
        .uploadFileLessons(
          f,
          moment(this.from).format("YYYY-MM-DD"),
          moment(this.to).format("YYYY-MM-DD")
        )
        .subscribe((resp) => {
          if (resp.type === HttpEventType.Response) {
            console.log("Upload complete");
            this.loading = false ;
            this.message = "Lezioni create con successo" ;
            this.dialogRef.close();
          }
          if (resp.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * resp.loaded) / resp.total);
            this.tmp.progress = percentDone;
            console.log("Progress " + percentDone + "%");
          }
        },(err)=>{
          this.loading = false ;
        });
    }
  }
}
