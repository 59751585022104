import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ClassesService } from '../../../../../core/service/classes.service';
import { HttpErrorResponse } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { ClassModel } from 'src/app/core/models/class.model';
import { CoursesService } from 'src/app/core/service/courses.service';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }]
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  libraryForm: FormGroup;
  library: ClassModel;
  students:any[] = [] ;
  loading = false ;
  lessons:any[] ;
  coursesList = this.course.Courses.pipe(map(data=>{
    return data.data ;
  }))


  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public libraryService: ClassesService,
    private fb: FormBuilder,
    private course:CoursesService,
    private classService : ClassesService 
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === 'edit') {
      console.log(data.class);
      this.dialogTitle = data.class.name;
      this.library = data.class;
      this.loadClass(this.library.id) ;

    } else {
      this.dialogTitle = 'Nuova classe';
      this.library = new ClassModel({});
    }
    this.libraryForm = this.createContactForm();
  }
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);
  loadClass(id){
    this.classService.getClass(id).subscribe((data:any)=>{
      this.students = data.data.students ;
      this.lessons = data.data.lessons ;
    })
  }
  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : this.formControl.hasError('email')
      ? 'Not a valid email'
      : '';
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      id: [this.library.id],
      name: [this.library.name, [Validators.required]],
      year: [this.library.year, [Validators.required]],
      course : [this.library.course,[Validators.required]],
      telegram : [this.library.telegram,[]]
    });
  }
  removeStudent(id){
    this.classService.deleteStudent(this.library.id, id).subscribe( data => {
      if( data.success ){
        this.loadClass(this.library.id) ;
      }
    })
  }
  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd() {
    this.loading = true ;
    this.libraryService.addClass(this.libraryForm.getRawValue()).subscribe(data=>{
      this.loading = false ;
      if(data.success){
        

        this.dialogRef.close(1);
        
      }
      else{
        this.dialogRef.close(-1);
      }
    },
    (err: HttpErrorResponse) => {
      this.dialogRef.close(-1);
    });
  }
}
