import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBtnDirective } from './loading-btn.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [LoadingBtnDirective],
  exports: [LoadingBtnDirective],
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class LoadingBtnModule {}
