<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">
            {{ dialogTitle }}
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content *ngIf="classesList | async as cl">
    <form
      class="register-form m-4"
      [formGroup]="libraryForm"
      (ngSubmit)="(submit)"
    >
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" required />
            <mat-icon matSuffix>receipt</mat-icon>
            <mat-error *ngIf="libraryForm.get('name').hasError('required')">
              Inserisci il nome
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" required />
            <mat-icon matSuffix>credit_card</mat-icon>
            <mat-error *ngIf="libraryForm.get('email').hasError('required')">
              Inserisci una email per il login
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Data di nascita</mat-label>
            <input
              matInput
              type="text"
              formControlName="birthdate"
              [matDatepicker]="picker"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error
              *ngIf="libraryForm.get('birthdate').hasError('required')"
            >
              Inserisci la data di nascita dello studente
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Indirizzo</mat-label>
            <input matInput type="text" formControlName="address" required />

            <mat-icon matSuffix>credit_card</mat-icon>
            <mat-error *ngIf="libraryForm.get('address').hasError('required')">
              Inserisci l'indirizzo di residenza
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Telefono</mat-label>
            <input matInput type="text" formControlName="phone" required />

            <mat-icon matSuffix>credit_card</mat-icon>
            <mat-error *ngIf="libraryForm.get('phone').hasError('required')">
              Inserisci il numero di telefono dello studente
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Classe</mat-label>
            <mat-select formControlName="class" required>
              <mat-option *ngFor="let c of cl" [value]="c.id">{{
                c.name
              }}</mat-option>
            </mat-select>

            <mat-icon matSuffix>credit_card</mat-icon>
            <mat-error *ngIf="libraryForm.get('phone').hasError('required')">
              Inserisci la classe di appartenenza dello studente
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <fieldset>
        <label>Refente</label>
        <div class="row" formGroupName="parent">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field
              class="example-full-width mb-3"
              appearance="outline"
            >
              <mat-label>Nome</mat-label>
              <input matInput type="text" formControlName="name" />
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
            <mat-form-field
              class="example-full-width mb-3"
              appearance="outline"
            >
              <mat-label>Telefono</mat-label>
              <input matInput type="text" formControlName="phone" />
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
            <mat-form-field
              class="example-full-width mb-3"
              appearance="outline"
            >
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" />
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <div class="row" *ngIf="hasErrors()">
        
        <div class="error col-sm-12" *ngFor="let item of errors | keyvalue">
          <div class="col-sm-2">{{ item.key }}:</div>
          
          <div class="col-sm-9" *ngFor="let e of item.value"> {{ e }}</div>
         
        </div>
    
    </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="example-button-row">
            <button
              mat-raised-button
              [loading]="loading"
              color="primary"
              [type]="submit"
              [disabled]="!libraryForm.valid"
              (click)="confirmAdd()"
            >
              Salva
            </button>
            <button
              mat-raised-button
              color="warn"
              (click)="onNoClick()"
              tabindex="-1"
            >
              Annulla
            </button>
            <button
              mat-raised-button
              color="primary"
              *ngIf="library.id"
              (click)="openRegister()"
            >
              Registro studente
            </button>
           

            <a *ngIf="library.id && library.parentcode" target="_blank" [href]="'https://wa.me/'+library.parent.phone+'/?text='+shareText()" style="display: inline-block; padding:8px; border-radius: 8px; background-color: #25D366; color: #fff; text-decoration: none; font-family: sans-serif; font-size: 16px;">Conividi registro</a>


          </div>
        </div>
      </div>

      
    </form>
  </div>
</div>
