import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { RegisterRespose } from "../models/register.model";

@Injectable()
export class RegisterService {
  private readonly API_URL = environment.apiUrl + "/api/register";

  constructor(private httpClient: HttpClient) {}

  /** CRUD METHODS */
  getRegister(lesson, student = null) {
    return this.httpClient.get<RegisterRespose>(
      this.API_URL + "/" + lesson + (student ? "/" + student : "")
    );
  }
  saveRegister(data) {
    return this.httpClient.post(this.API_URL + "/save", data);
  }

  getSharedRegister(code, password) {
    return this.httpClient.get(
      this.API_URL + "/share/" + code + "/" + password
    );
  }
}
