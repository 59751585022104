import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Student, StudentRespose } from "../models/student.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class StudentService {
  private readonly API_URL = environment.apiUrl + "/api/students";
  isTblLoading = true;
  dataChange: BehaviorSubject<Student[]> = new BehaviorSubject<Student[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Student[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllStudents(): void {
    this.httpClient.get<StudentRespose>(this.API_URL + "/all").subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }
  get Students() {
    return this.httpClient.get<StudentRespose>(this.API_URL + "/all");
  }

  addStudent(student: Student) {
    //this.dialogData = student;
    if (student.id == 0) {
      delete student.id;
    }
    return this.httpClient.post(this.API_URL + "/save", student).pipe(
      map((data: any) => {
        if (data.success) {
          this.dialogData = data.data;
        }
        return data;
      })
    );
  }

  student(id: number) {
    return this.httpClient.get(this.API_URL + "/student/" + id);
  }


  


  deleteStudent(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(
      map((data: any) => {
        console.log(data);
        return data;
      })
    );
  }
}
