import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListsubjectcsComponent} from './listsubjects/listsubject.component'
const routes: Routes = [{
  path: '',
  redirectTo: 'main',
  pathMatch: 'full',
},
{
  path: 'main',
  component: ListsubjectcsComponent,
},];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubjectsRoutingModule { }
