import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import {RegisterService} from '../../../../../core/service/register.service'
@Component({
  selector: 'app-registerview',
  templateUrl: './registerview.component.html',
  styleUrls: ['./registerview.component.sass']
})
export class RegisterviewComponent implements OnInit {
  registerLesson:any ;
  lesson : any ;
  constructor(public dialogRef: MatDialogRef<RegisterviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public registerService: RegisterService) {
      this.lesson = data.lesson ;
      this.registerService.getRegister(data.lesson.id).subscribe( (data:any)=>{
        console.log(data.data);
        this.registerLesson = data.data ;
      } )


    }
    onNoClick(): void {
      this.dialogRef.close();
    }

  getDate(data){
    return moment(data).format("DD-MM-YYYY HH:mm") ;
  }

  getClasses(){
    return this.registerLesson.classes.map( c => c.name).join(", ")
  }
  ngOnInit(): void {
  }

}
