<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">
            {{ dialogTitle }}
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content style="overflow-x:hidden ;">
    <form
      class="register-form m-4"
      [formGroup]="libraryForm"
      (ngSubmit)="(submit)"
    >
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" required />
            <mat-icon matSuffix>receipt</mat-icon>
            <mat-error *ngIf="libraryForm.get('name').hasError('required')">
              Inserisci il nome
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Anno scolastico</mat-label>
            <input matInput formControlName="year" required />
            <mat-icon matSuffix>calendar</mat-icon>
            <mat-error
              *ngIf="libraryForm.get('year').hasError('required')"
            >
              Inserisci un anno scolastico
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
          *ngIf="coursesList | async as cl"
        >
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Seleziona il corso della classe</mat-label>
            <mat-select formControlName="course" [(ngModel)]="library.course">
              <mat-option *ngFor="let c of cl" [value]="c.id">
                {{ c.name }} ({{c.description}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
        
      >
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>Inserisci il gruppo telegram</mat-label>
         
          <input matInput formControlName="telegram" />
          <mat-icon matSuffix>receipt</mat-icon>
          <mat-error *ngIf="libraryForm.get('telegram').hasError('required')">
            Inserisci il gruppo telegram
          </mat-error>
        </mat-form-field>
      </div>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
        <h3>Studenti</h3>

        <mat-list role="list" *ngIf="students.length">
          <mat-list-item role="listitem" *ngFor="let student of students"
            >{{ student.name }}
            <button mat-icon-button click="removeStudent(student.id)">
              <mat-icon>delete</mat-icon>
            </button></mat-list-item
          >
        </mat-list>
        <p *ngIf="students.length == 0">
          Non ci sono studenti registrati in questo corso
        </p>
      
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="example-button-row">
            <button
              mat-raised-button
              color="primary"
              [type]="submit"
              [disabled]="!libraryForm.valid"
              [mat-dialog-close]="1"
              (click)="confirmAdd()"
            >
              Salva
            </button>
            <button
              [loading]="loading"
              mat-raised-button
              color="warn"
              (click)="onNoClick()"
              tabindex="-1"
            >
              Annulla
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
