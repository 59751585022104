import { TeacherService } from 'src/app/core/service/teacher.service';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClassesService } from "src/app/core/service/classes.service";
import { MatPaginator } from "@angular/material/paginator";
import * as moment from "moment";
import { ClassFileModel, ClassModel } from "src/app/core/models/class.model";
import { MatTableDataSource } from "@angular/material/table";
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from "ngx-file-drop";
import { HttpEventType } from "@angular/common/http";

@Component({
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.sass"],
})
export class UploadFileComponent implements OnInit {
  dialogTitle: string;
  displayedColumns = ["teacher", "subject", "name", "note", "date"];
  library: ClassModel;
  dataSource: MatTableDataSource<ClassFileModel> =
    new MatTableDataSource<ClassFileModel>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  teacher = "";
  subject = "";
  student = "";
  fileToLoads = [];
  classData;
  tmp = { file: "", progress: 0 };
  allClasses = [];
  seletectedClass = [] ;
  allTeachers = [] ;
  constructor(
    public dialogRef: MatDialogRef<UploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public classService: ClassesService,
    public teacherService: TeacherService
  ) {
    this.dialogTitle = data.class.name;
    this.library = data.class;
    this.seletectedClass.push(this.library.id) ;
    this.loadClass(this.library.id);
  }
  async ngOnInit() {
    this.allTeachers = (await this.teacherService.getTeachers().toPromise()).data ;
    this.allClasses =  (await this.classService.Classes.toPromise()).data ;
  }
  loadClass(id) {
    this.classService.getClass(id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<ClassFileModel>(data.data.files);
      this.dataSource.paginator = this.paginator;
      if (data.data.teachers.length) {
        this.teacher = data.data.teachers[0].id;
      }
      if (data.data.subjects.length) {
        this.subject = data.data.subjects[0].id;
      }

      this.classData = data;
    });
  }
  getTeacheNameById(id) {
    for (let p of this.classData.data.teachers) {
      if (p.id == id) {
        return p.name;
      }
    }
    return "";
  }
  getSubjectNameById(id) {
    for (let s of this.classData.data.subjects) {
      if (s.id == id) return s.name;
    }
    return "";
  }

  getDate(date) {
    return moment(date).toDate();
  }

  public files: NgxFileDropEntry[] = [];
  public loadingFile: any[] = [];

  public caricaSingoloFile() {
    for (let f of this.fileToLoads) {
      this.tmp = { file: f, progress: 0 };
      this.classService
        .saveFile(f, this.teacher, this.subject, this.seletectedClass, this.student)
        .subscribe((resp) => {
          if (resp.type === HttpEventType.Response) {
            console.log("Upload complete");
            this.loadClass(this.library.id);
          }
          if (resp.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * resp.loaded) / resp.total);
            this.tmp.progress = percentDone;
            console.log("Progress " + percentDone + "%");
          }
        });
    }
  }

  public getStudent(id) {
    for (let s of this.classData.data.students) {
      if (s.id == id) return s.name;
    }
    return "";
  }

  public getNote(row) {
    if (row.loaded == "student") {
      return "Caricato da " + this.getStudent(row.student);
    }

    if (row.student == "student") {
      return "Condiviso solo a " + this.getStudent(row.student);
    }

    return "Condiviso con tutti";
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.loadingFile = [];
    this.tmp = { file: "", progress: 0 };
    this.fileToLoads = [];
    for (const droppedFile of files) {
      let tmp = { file: droppedFile, progress: 0 };
      this.loadingFile.push(tmp);
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          this.fileToLoads.push(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
}
