import { formatDate } from '@angular/common';
export class Teacher {
  id: number;
  name: string;
  email: string;
  password : string ;
 
 
  constructor(teacher) {
    {
      this.id = teacher.id || 0;
      this.name = teacher.name || '';
      this.email = teacher.email || '';
      this.password = 'password' ;
    }
  }

}

export class TeacherRespose {
  success: boolean;
  data: Teacher[];
  message: string;
 
 
  

}
