import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { Lesson } from "../../../../../core/models/lesson.model";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { formatDate } from "@angular/common";
import { LessonsService } from "../../../../../core/service/lessons.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ClassesService } from "src/app/core/service/classes.service";
import { map } from "rxjs/operators";
import { SubjectService } from "src/app/core/service/subject.service";
import { TeacherService } from "src/app/core/service/teacher.service";
import { RegisterviewComponent } from "../registerview/registerview.component";
import { StudentService } from "src/app/core/service/student.service";

@Component({
  selector: "app-form-dialog",
  templateUrl: "./form-dialog.component.html",
  styleUrls: ["./form-dialog.component.sass"],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "it-IT" }],
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  libraryForm: FormGroup;
  library: Lesson;
  classValue = 0;
  message = "Invia Notifica lezione";
  classesList = this.classes.Classes.pipe(
    map((data) => {
      return data.data;
    })
  );
  subjectList = this.subjects.Subjects.pipe(
    map((data) => {
      return data.data;
    })
  );
  teachersList = this.teachers.Teachers.pipe(
    map((data) => {
      return data.data;
    })
  );
  studentsList = this.students.Students.pipe(
    map((data) => {
      return data.data;
    })
  );
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public libraryService: LessonsService,
    private fb: FormBuilder,
    public classes: ClassesService,
    public subjects: SubjectService,
    public teachers: TeacherService,
    public students: StudentService,
    public dialog: MatDialog
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === "edit") {
      this.dialogTitle = "Lezione di " + data.lesson.subjectname;
      if (data.lesson.classes.length && data.lesson.classes[0].id) {
        data.lesson.classes = data.lesson.classes.map((l) => l.id);
      }
      if (data.lesson.invstudent.length && data.lesson.invstudent[0].id) {
        data.lesson.invstudent = data.lesson.invstudent.map((l) => l.id);
      }
      this.library = data.lesson;
    } else {
      console.log("Data lezione");
      this.dialogTitle = "Nuova lezione";
      this.library = new Lesson({});
      if (data.startday) {
        this.library.start = data.startday;
        this.library.end = data.startday;
      }
    }
    this.libraryForm = this.createContactForm();
  }
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
      ? "Not a valid email"
      : "";
  }
  onChange($event) {
    const start = new Date($event);
    const end = new Date(this.libraryForm.value.end);
    end.setFullYear(start.getFullYear());
    end.setMonth(start.getMonth());
    end.setDate(start.getDate());
    this.libraryForm.patchValue({
      end: end,
    });
  }

  createContactForm(): FormGroup {
    return this.fb.group({
      id: [this.library.id],
      subject: [this.library.subject, [Validators.required]],
      teacher: [this.library.teacher, [Validators.required]],
      start: [new Date(this.library.start), [Validators.required]],
      end: [new Date(this.library.end), [Validators.required]],
      classes: [this.library.classes, [Validators.required]],
      repeat: [1],
      students: [this.library.invstudent],
    });
  }
  submit() {
    // emppty stuff
  }

  SendNotification() {
    if ((this.message = "Invia Notifica lezione")) {
      this.message = "Invio in corso...";
      this.libraryService
        .sendNotification(this.library.id)
        .subscribe((data) => {
          this.message = "Notifiche inviate";
        });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {
    this.libraryService.addLesson(this.libraryForm.getRawValue()).subscribe(
      (data) => {
        if (data.success) {
          this.dialogRef.close(1);
        } else {
          this.dialogRef.close(-1);
        }
      },
      (err: HttpErrorResponse) => {
        this.dialogRef.close(-1);
      }
    );
  }

  public OpenRegister() {
    const dialogRef = this.dialog.open(RegisterviewComponent, {
      data: {
        lesson: this.library,
      },
    });
  }
}
