import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { Subject } from '../../../../../core/models/subject.model';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { SubjectService } from '../../../../../core/service/subject.service';
import { HttpErrorResponse } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { CoursesService } from 'src/app/core/service/courses.service';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }]
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  libraryForm: FormGroup;
  library: Subject;
  loading = false ;
  courseList = this.courses.Courses.pipe(map(data=>{
    return data.data ;
  }))

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public libraryService: SubjectService,
    private fb: FormBuilder,
    private courses:CoursesService
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === 'edit') {
      console.log(data.subject);
      this.dialogTitle = data.subject.name;
      this.library = data.subject;
      if( this.library.courses?.length && this.library.courses[0].id)
      this.library.courses = this.library.courses.map(c=>c.id) ;
    } else {
      this.dialogTitle = 'Nuova Materia';
      this.library = new Subject({});
    }
    this.libraryForm = this.createContactForm();
  }
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);


 

  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : this.formControl.hasError('email')
      ? 'Not a valid email'
      : '';
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      id: [this.library.id],
      name: [this.library.name, [Validators.required]],
      description: [this.library.description, [Validators.required]] ,
      courses : [this.library.courses]
    });
  }
  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {
    this.loading = true ;
    this.libraryService.addSubject(this.libraryForm.getRawValue()).subscribe(data=>{
      this.loading = false ;
      if(data.success){
        this.libraryService.addCourses(data.data.id, this.libraryForm.value.courses).subscribe(
          result => {
            this.dialogRef.close(1);
          } , 
          (err: HttpErrorResponse) => {
            this.dialogRef.close(-1);
          }
        )
       
      }
      else{
        this.dialogRef.close(-1);
      }
    },
    (err: HttpErrorResponse) => {
      this.dialogRef.close(-1);
    });
  }
}
