import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListcoursesComponent } from './listcourses/listcourses.component';

const routes: Routes = [{
  path: '',
  redirectTo: 'main',
  pathMatch: 'full',
},
{
  path: 'main',
  component: ListcoursesComponent,
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesRoutingModule { }
