import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-selector',
  templateUrl: './courses-selector.component.html',
  styleUrls: ['./courses-selector.component.sass']
})
export class CoursesSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
