<div class="addContainer">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <div class="modal-about">
                    <div class="font-weight-bold p-t-5 font-17">
                        {{dialogTitle}}</div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form class="register-form m-4" [formGroup]="libraryForm" (ngSubmit)="submit">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-icon matSuffix>receipt</mat-icon>
                        <mat-error *ngIf="libraryForm.get('name').hasError('required')">
                            Inserisci il nome
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                        <mat-label>Descrizione</mat-label>
                        <input matInput type="text" formControlName="description" required>
                        <mat-icon matSuffix>credit_card</mat-icon>
                        <mat-error *ngIf="libraryForm.get('description').hasError('required')">
                            Inserisci la descrizione della materia
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="(courseList | async) as cl">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                        <mat-label>Seleziona i corsi di appartenenza</mat-label>
                        <mat-select  formControlName="courses" multiple>
                            <mat-option *ngFor="let c of cl" [value]="c.id">
                                {{c.name}} <i>{{c.description}}</i>
                              </mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
     
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <div class="example-button-row">
                        <button mat-raised-button [loading]="loading" color="primary" [type]="submit" [disabled]="!libraryForm.valid"
                           (click)="confirmAdd()">Salva</button>
                        <button mat-raised-button color="warn" (click)="onNoClick()" tabindex="-1">Annulla</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>