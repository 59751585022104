import { formatDate } from '@angular/common';
export class ClassModel {
  id: number;
  name: string;
  year: string;
  course : number ;
  coursename : string ;
  telegram : string ;
 
 
  constructor(classm) {
    {
      this.id = classm.id || 0;
      this.name = classm.name || '';
      this.year = classm.year || (new Date().getFullYear())+"/"+(new Date().getFullYear()+1);
      this.course = classm.course || 0;
      this.coursename = "" ;
      this.telegram = classm.telegram || "" ;
    }
  }

}

export class ClassRespose {
  success: boolean;
  data: ClassModel[];
  message: string;

}

export class ClassFileModel{
  id : number ;
  teacher : number ;
  subject : number ;
  name : string ;
  create_at : string ;
}