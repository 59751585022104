<section class="content">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul class="breadcrumb breadcrumb-style ">
              <li class="breadcrumb-item">
                <h4 class="page-title">Calendario Lezioni</h4>
              </li>
              <li class="breadcrumb-item bcrumb-1">
                <a href="#" onClick="return false;">
                  <i class="fas fa-home"></i> Home</a>
              </li>
              <li class="breadcrumb-item active">Lezioni</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-12 p-l-30">
                  <div class="m-b-25">
                    <button class="button_action" mat-raised-button color="primary" style="color:#fff;" (click)="addNewEvent()"><mat-icon>library_add</mat-icon> Lezione</button>
                    <button class="button_action" mat-raised-button color="warn" style="color:#fff;" (click)="downloadCSV()"><mat-icon>cloud_download</mat-icon> Scarica CSV</button>
                    <button class="button_action" mat-raised-button color="accent" style="color:#fff;" (click)="vacation_day()"><mat-icon>calendar_today</mat-icon> Vacanze</button>
                    <button class="button_action btn-outline-warning" mat-raised-button  style="color:#fff;" (click)="upload_lessons()"><mat-icon>cloud_upload</mat-icon> Carica lezioni</button>
                  </div>
                  <p>Classi</p>
                  <div class="filter-container">
                    <ul>
                      <li *ngFor="let filter of filters">
                        <mat-checkbox [checked]="filter.checked" [value]="filter.value" color="primary"
                          (change)="changeCategory($event, filter)">
                          {{filter.name}}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-10 col-sm-12">
                  <div class="panel-body">
                    <full-calendar  #calendarEl [options]='calendarOptions'>
                    </full-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
