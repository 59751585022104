import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { StudentService } from 'src/app/core/service/student.service';


@Component({
  selector: 'app-registerview',
  templateUrl: './registerview.component.html',
  styleUrls: ['./registerview.component.sass']
})
export class RegisterviewComponent implements OnInit {

  student:any ;
  totalLessons = 0  ;
  presenceLessons = 0  ;
  voteSubjects = [] ;
  subjectLessons = [] ;


  constructor(public dialogRef: MatDialogRef<RegisterviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public studentService: StudentService) { 
      this.studentService.student(data.student.id).subscribe( (data:any) => { 
        this.student = data.data ;
        this.calculate() ;
      }) ;
    }

  ngOnInit(): void {
      
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  calculate(){
    for( let subject of this.student.subjects){
      let tmp = {
        name : subject.name ,
        teacher : [] ,
        votes : [] ,
        lessons : {
          presence : 0 ,
          total : 0 ,
          date : new Date(),
          items : [] ,
          votes : []
        }
      }     
      for( let lesson of this.student.lessons)
      {
        if( lesson.subject == subject.id ){
            if( new Date(lesson.start) < new Date()){
              let item = {
                teacher : this.student.teachers.filter(t=>{return t.id==lesson.teacher})[0].name ,
                student : "" ,
                note : "",
                date: lesson.start
              }
              
              tmp.lessons.date = new Date(lesson.start);
              tmp.lessons.total ++ ;
              this.totalLessons++;
              //this.presenceLessons++ ;
              for(let lr of this.student.register){
                if( lr.lesson == lesson.id){
                  item.student = lr.note ;
                  if( lr.vote )
                    tmp.lessons.votes.push(lr.vote) ;
                  if(lr.presence.length){
                    tmp.lessons.presence++ ;
                    this.presenceLessons++ ;
                  }
                }
              }
              for(let lr of this.student.registerLesson){
                if( lr.lesson == lesson.id){
                  item.note = lr.note ;                  
                }
              }
              tmp.lessons.items.push(item) ;
             
            }
        }
      }
      
      this.subjectLessons.push(tmp) ;
    }
  }

}
