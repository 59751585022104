import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LessonsService } from "../../../core/service/lessons.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Lesson } from "../../../core/models/lesson.model";
import { DataSource } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, fromEvent, merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatMenuTrigger } from "@angular/material/menu";
import { SelectionModel } from "@angular/cdk/collections";
import { FormDialogComponent } from "./dialogs/form-dialog/form-dialog.component";
import { DeleteDialogComponent } from "./dialogs/delete/delete.component";
import * as moment from "moment";
@Component({
  templateUrl: "./listlessons.component.html",
  styleUrls: ["./listlessons.component.sass"],
})
export class ListlessoncsComponent implements OnInit {
  displayedColumns = [
    "date",
    "subjectname",
    "teachername",
    "classes",
    "actions",
  ];
  lessonDatabases: LessonsService | null;
  dataSource: LessonDataSource | null;
  selection = new SelectionModel<Lesson>(true, []);
  id: number;
  library: Lesson | null;
  public start: Date = new Date();
  public end: Date = new Date();
  constructor(
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public libraryService: LessonsService,
    private snackBar: MatSnackBar
  ) {}
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  ngOnInit() {
    this.end = moment(this.start).add(1, "days").toDate();
    this.loadData();
  }
  refresh() {
    this.loadData();
  }
  getDateOfLesson(row) {
    return (
      moment(row.start).format("DD-MM-YYYY HH:mm") +
      " - " +
      moment(row.end).format("HH:mm")
    );
  }
  getClasses(row) {
    return row.classes.map((c) => c.name).join(", ");
  }
  addNew() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        library: this.library,
        action: "add",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        const data = this.libraryService.getDialogData();

        while (data.repeat > 0) {
          data.start = new Date(data.start);

          data.start.setDate(data.start.getDate() + (data.repeat - 1) * 7);
          data.end = new Date(data.start);

          data.end.setDate(data.end.getDate() + (data.repeat - 1) * 7);
          this.lessonDatabases.dataChange.value.unshift(data);

          data.repeat--;
        }
        this.refreshTable();
        this.showNotification(
          "snackbar-success",
          "Lezione salvata correttamente",
          "bottom",
          "center"
        );
      } else if (result == -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nel salvare la lezione",
          "bottom",
          "center"
        );
      }
    });
  }
  editCall(row) {
    this.id = row.id;
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        lesson: row,
        action: "edit",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        const foundIndex = this.lessonDatabases.dataChange.value.findIndex(
          (x) => x.id === this.id
        );
        // Then you update that record using data from dialogData (values you enetered)
        this.lessonDatabases.dataChange.value[foundIndex] =
          this.libraryService.getDialogData();
        // And lastly refresh table
        this.refreshTable();
        this.showNotification(
          "black",
          "Lezione salvata correttamente",
          "bottom",
          "center"
        );
      } else if (result == -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nel salvare la lezione",
          "bottom",
          "center"
        );
      }
    });
  }
  deleteItem(row) {
    this.id = row.id;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        const foundIndex = this.lessonDatabases.dataChange.value.findIndex(
          (x) => x.id === this.id
        );
        // for delete we use splice in order to remove single object from DataService
        this.lessonDatabases.dataChange.value.splice(foundIndex, 1);
        this.refreshTable();
        this.showNotification(
          "snackbar-success",
          "Lessone eliminato correttamente",
          "bottom",
          "center"
        );
      } else if (result === -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nell'eliminare lo lessone",
          "bottom",
          "center"
        );
      }
    });
  }
  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.renderedData.forEach((row) =>
          this.selection.select(row)
        );
  }
  removeSelectedRows() {
    const totalSelect = this.selection.selected.length;
    this.selection.selected.forEach((item) => {
      const index: number = this.dataSource.renderedData.findIndex(
        (d) => d === item
      );
      // console.log(this.dataSource.renderedData.findIndex((d) => d === item));
      this.lessonDatabases.dataChange.value.splice(index, 1);
      this.refreshTable();
      this.selection = new SelectionModel<Lesson>(true, []);
    });
    this.showNotification(
      "snackbar-danger",
      totalSelect + " Record Delete Successfully...!!!",
      "bottom",
      "center"
    );
  }
  public loadData() {
    this.lessonDatabases = new LessonsService(this.httpClient);
    this.dataSource = new LessonDataSource(
      this.lessonDatabases,
      this.paginator,
      this.sort,
      this.start,
      this.end
    );
    fromEvent(this.filter.nativeElement, "keyup").subscribe(() => {
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter = this.filter.nativeElement.value;
    });
  }
  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
  // context menu
  onContextMenu(event: MouseEvent, item: Lesson) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }
}
export class LessonDataSource extends DataSource<Lesson> {
  filterChange = new BehaviorSubject("");
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: Lesson[] = [];
  renderedData: Lesson[] = [];
  constructor(
    public lessonDatabases: LessonsService,
    public paginator: MatPaginator,
    public _sort: MatSort,
    public start: Date,
    public end: Date
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Lesson[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.lessonDatabases.dataChange,
      this._sort.sortChange,
      this.filterChange,
      this.paginator.page,
    ];
    this.lessonDatabases.getAllLessons(
      moment(this.start).format("yyyy-MM-DD"),
      moment(this.end).format("yyyy-MM-DD")
    );
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data

        this.filteredData = this.lessonDatabases.data
          .slice()
          .filter((library: Lesson) => {
            let classesName = [];
            for (let c of library.classes) {
              classesName.push[c.name];
            }
            const searchStr = (
              library.id +
              library.teachername +
              library.subjectname +
              classesName.join(" ")
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() {}
  /** Returns a sorted copy of the database data. */
  sortData(data: Lesson[]): Lesson[] {
    if (!this._sort.active || this._sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";
      switch (this._sort.active) {
        case "id":
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case "teachername":
          [propertyA, propertyB] = [a.teachername, b.teachername];
          break;
        case "subjectname":
          [propertyA, propertyB] = [a.subjectname, b.subjectname];
          break;
        case "start":
          [propertyA, propertyB] = [
            new Date(a.start).getTime(),
            new Date(b.start).getTime(),
          ];
          break;
      }
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1)
      );
    });
  }
}
