import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { StudentService } from "../../../core/service/student.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Student } from "../../../core/models/student.model";
import { DataSource } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, fromEvent, merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatMenuTrigger } from "@angular/material/menu";
import { SelectionModel } from "@angular/cdk/collections";
import { FormDialogComponent } from "./dialogs/form-dialog/form-dialog.component";
import { DeleteDialogComponent } from "./dialogs/delete/delete.component";

@Component({
  templateUrl: "./liststudents.component.html",
  styleUrls: ["./liststudents.component.sass"],
})
export class ListstudentcsComponent implements OnInit {
  displayedColumns = ["name", "email", "classname", "actions"];
  studentDatabases: StudentService | null;
  dataSource: StudentDataSource | null;
  selection = new SelectionModel<Student>(true, []);
  id: number;
  library: Student | null;
  constructor(
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public libraryService: StudentService,
    private snackBar: MatSnackBar
  ) {}
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  ngOnInit() {
    this.loadData();
  }
  refresh() {
    this.loadData();
  }
  addNew() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        library: this.library,
        action: "add",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        this.studentDatabases.dataChange.value.unshift(
          this.libraryService.getDialogData()
        );
        this.refreshTable();
        this.showNotification(
          "snackbar-success",
          "Studente salvato correttamente",
          "bottom",
          "center"
        );
      } else if (result == -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nel salvare lo studente",
          "bottom",
          "center"
        );
      }
    });
  }
  editCall(row) {
    this.id = row.id;

    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        student: row,
        action: "edit",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        const foundIndex = this.studentDatabases.dataChange.value.findIndex(
          (x) => x.id === this.id
        );
        // Then you update that record using data from dialogData (values you enetered)
        this.studentDatabases.dataChange.value[foundIndex] =
          this.libraryService.getDialogData();
        // And lastly refresh table
        this.refreshTable();
        this.showNotification(
          "black",
          "Studente salvato correttamente",
          "bottom",
          "center"
        );
      } else if (result == -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nel salvare lo studente",
          "bottom",
          "center"
        );
      }
    });
  }
  deleteItem(row) {
    this.id = row.id;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        const foundIndex = this.studentDatabases.dataChange.value.findIndex(
          (x) => x.id === this.id
        );
        // for delete we use splice in order to remove single object from DataService
        this.studentDatabases.dataChange.value.splice(foundIndex, 1);
        this.refreshTable();
        this.showNotification(
          "snackbar-success",
          "Studente eliminato correttamente",
          "bottom",
          "center"
        );
      } else if (result === -1) {
        this.showNotification(
          "snackbar-danger",
          "Errore nell'eliminare lo studente",
          "bottom",
          "center"
        );
      }
    });
  }
  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.renderedData.forEach((row) =>
          this.selection.select(row)
        );
  }
  removeSelectedRows() {
    const totalSelect = this.selection.selected.length;
    this.selection.selected.forEach((item) => {
      const index: number = this.dataSource.renderedData.findIndex(
        (d) => d === item
      );
      // console.log(this.dataSource.renderedData.findIndex((d) => d === item));
      this.studentDatabases.dataChange.value.splice(index, 1);
      this.refreshTable();
      this.selection = new SelectionModel<Student>(true, []);
    });
    this.showNotification(
      "snackbar-danger",
      totalSelect + " Record Delete Successfully...!!!",
      "bottom",
      "center"
    );
  }
  public loadData() {
    this.studentDatabases = new StudentService(this.httpClient);
    this.dataSource = new StudentDataSource(
      this.studentDatabases,
      this.paginator,
      this.sort
    );
    fromEvent(this.filter.nativeElement, "keyup").subscribe(() => {
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter = this.filter.nativeElement.value;
    });
  }
  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
  // context menu
  onContextMenu(event: MouseEvent, item: Student) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  shareRegister(row) {
    console.log("Share with whatapp");
    const text = `Scuola Virtuale - Registro scolastico ${row.name} - 
    https://demo.scuolavirtuale.bytecareitalia.com/#/registroalunno/${row.parentcode}
    Usa la password ${row.parent.password}`;
    let url = "";
    if (row.parent.phone) {
      url =
        "https://wa.me/" +
        row.parent.phone +
        "/?text=" +
        encodeURIComponent(text);
    }
    else{
      url =
        "https://wa.me/" +

        "?text=" +
        encodeURIComponent(text);
    }
    (window as any).open(url, "_blank");
  }
}
export class StudentDataSource extends DataSource<Student> {
  filterChange = new BehaviorSubject("");
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: Student[] = [];
  renderedData: Student[] = [];
  constructor(
    public studentDatabases: StudentService,
    public paginator: MatPaginator,
    public _sort: MatSort
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Student[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.studentDatabases.dataChange,
      this._sort.sortChange,
      this.filterChange,
      this.paginator.page,
    ];
    this.studentDatabases.getAllStudents();
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.studentDatabases.data
          .slice()
          .filter((library: Student) => {
            const searchStr = (
              library.id +
              library.name +
              library.email
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() {}
  /** Returns a sorted copy of the database data. */
  sortData(data: Student[]): Student[] {
    if (!this._sort.active || this._sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";
      switch (this._sort.active) {
        case "id":
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case "name":
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case "description":
          [propertyA, propertyB] = [a.email, b.email];
          break;
      }
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1)
      );
    });
  }
}
