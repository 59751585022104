<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">
            {{ dialogTitle }}
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="subjectList | async as su">
    <ng-container *ngIf="teachersList | async as te">
      <ng-container *ngIf="classesList | async as cl">
        <ng-container *ngIf="studentsList | async as sl">
          <div mat-dialog-content>
            <form class="register-form m-4" [formGroup]="libraryForm" (ngSubmit)="(submit)">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Materia</mat-label>

                    <mat-select matInput formControlName="subject" required>
                      <mat-option *ngFor="let item of su" [value]="item.id">{{
                        item.name
                        }} - {{item.description}}</mat-option>
                    </mat-select>

                    <mat-icon matSuffix>receipt</mat-icon>
                    <mat-error *ngIf="libraryForm.get('subject').hasError('required')">
                      Inserisci la materia
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Professore</mat-label>
                    <mat-select matInput formControlName="teacher" required>
                      <mat-option *ngFor="let item of te" [value]="item.id">{{
                        item.name
                        }}</mat-option>
                    </mat-select>

                    <mat-icon matSuffix>credit_card</mat-icon>
                    <mat-error *ngIf="libraryForm.get('teacher').hasError('required')">
                      Inserisci una email per il login
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Data e ora inizio lezione</mat-label>
                    <input matInput formControlName="start" [owlDateTimeTrigger]="startDate" [owlDateTime]="startDate"
                      placeholder="Scegli la data" (ngModelChange)="onChange($event)" required />
                    <mat-icon matSuffix [owlDateTimeTrigger]="startDate" class="date-icon">today</mat-icon>
                    <owl-date-time #startDate></owl-date-time>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Data e ora fine lezione</mat-label>
                    <input matInput formControlName="end" [owlDateTimeTrigger]="endDate" [owlDateTime]="endDate"
                      placeholder="Scegli l'ora" required />
                    <mat-icon matSuffix [owlDateTimeTrigger]="endDate" class="date-icon">today</mat-icon>
                    <owl-date-time #endDate></owl-date-time>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Classi</mat-label>
                    <mat-select matInput formControlName="classes" multiple required>
                      <mat-option *ngFor="let item of cl" [value]="item.id">{{
                        item.name
                        }}</mat-option>
                    </mat-select>

                    <mat-icon matSuffix>credit_card</mat-icon>
                    <mat-error *ngIf="libraryForm.get('classes').hasError('required')">
                      Inserisci le classi
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="!this.library.id">
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Ripeti per le prossime settimane</mat-label>
                    <input matInput type="number" formControlName="repeat" required />

                    <mat-icon matSuffix>credit_card</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                  <mat-form-field class="example-full-width mb-3" appearance="outline">
                    <mat-label>Invita studenti</mat-label>
                    <mat-select matInput formControlName="students" multiple>
                      <mat-option *ngFor="let item of sl" [value]="item.id">{{
                        item.name
                        }}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>user</mat-icon>
                  
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div class="example-button-row">
                    <button mat-raised-button color="primary" [type]="submit" [disabled]="!libraryForm.valid"
                      [mat-dialog-close]="1" (click)="confirmAdd()">
                      Salva
                    </button>
                    <button mat-raised-button color="warn" (click)="onNoClick()" tabindex="-1">
                      Annulla
                    </button>
                    <button mat-raised-button color="primary" *ngIf="library.id" (click)="OpenRegister()">
                      Registro della lezione
                    </button>
                    <button mat-raised-button color="primary" *ngIf="library.id" (click)="SendNotification()">
                      {{message}}
                    </button>
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>