import { formatDate } from '@angular/common';
export class Course {
  id: number;
  name: string;
  description: string;
  subjects : any[] ;
 
 
  constructor(course) {
    {
      this.id = course.id || 0;
      this.name = course.name || '';
      this.description = course.description || '';
      this.subjects = course.subjects || [];
    }
  }

}

export class CourseRespose {
  success: boolean;
  data: Course[];
  message: string;
 
 
  

}
