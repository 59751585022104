
<div class="container">
  <h3 mat-dialog-title>Giorni di vacanza</h3>
  <div mat-dialog-content >
    <mat-spinner color="accent" *ngIf="loader" class="centerLoader"></mat-spinner>
    <mat-card class="demo-inline-calendar-card vacation_day_container" *ngIf="!loader">
      <mat-calendar [(selected)]="selected"  [dateClass]="dateClass" (selectedChange)="selectedDate($event)"></mat-calendar>
    </mat-card>
  </div>
  <div mat-dialog-actions class="mb-1">
      <button mat-flat-button color="warn" [mat-dialog-close]="1" (click)="confirm()">
          Salva
      </button>
      <button mat-flat-button (click)="onNoClick()" tabindex="-1">Annulla</button>
  </div>
</div>

