<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style">
            <li class="breadcrumb-item">
              <h4 class="page-title">Classi</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/admin/dashboard/main">
                <i class="fas fa-home"></i> Home</a
              >
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="#" onClick="return false;">Classi</a>
            </li>
            <li class="breadcrumb-item active">Gestisci</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <div class="responsive_table">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-8">
                    <ul class="header-buttons-left ml-0">
                      <li class="dropdown">
                        <h2>
                          <strong>Classi</strong>
                        </h2>
                      </li>
                      <li class="dropdown m-l-20">
                        <label for="search-input"
                          ><i class="material-icons search-icon"
                            >search</i
                          ></label
                        >
                        <input
                          placeholder="Ricerca"
                          type="text"
                          #filter
                          class="browser-default search-field"
                          aria-label="Search box"
                        />
                      </li>
                      <li>
                        <div
                          class="icon-button-demo m-l-10"
                          [hidden]="!selection.hasValue()"
                        >
                          <button
                            mat-mini-fab
                            color="warn"
                            (click)="removeSelectedRows()"
                          >
                            <mat-icon class="col-white">delete </mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4">
                    <ul class="header-buttons">
                      <li>
                        <div class="icon-button-demo">
                          <button
                            mat-mini-fab
                            color="primary"
                            (click)="addNew()"
                          >
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div class="icon-button-demo">
                          <button
                            mat-mini-fab
                            color="primary"
                            (click)="refresh()"
                          >
                            <mat-icon class="col-white">refresh</mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
             
                <mat-table
                  #table
                  [dataSource]="dataSource"
                  matSort
                  class="mat-cell"
                >
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <mat-header-cell
                      *matHeaderCellDef
                      [ngClass]="'tbl-col-width-per-6'"
                    >
                      <mat-checkbox
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        "
                        [ngClass]="'tbl-checkbox'"
                      >
                      </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      [ngClass]="'tbl-col-width-per-6'"
                    >
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [ngClass]="'tbl-checkbox'"
                      >
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <!-- ID Column -->
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Id</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Nome</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      (contextmenu)="onContextMenu($event, row)"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>
                  <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Anno scolastico</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      (contextmenu)="onContextMenu($event, row)"
                    >
                      {{ row.year }}</mat-cell
                    >
                  </ng-container>
                  <ng-container matColumnDef="coursename">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Corso</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      (contextmenu)="onContextMenu($event, row)"
                    >
                      {{ row.coursename }}</mat-cell
                    >
                  </ng-container>
                  <ng-container matColumnDef="students">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Studenti</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      (contextmenu)="onContextMenu($event, row)"
                    >
                      {{ row.students }}</mat-cell
                    >
                  </ng-container>

                  <!-- actions -->
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="pr-0"
                      >Actions</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row; let i = index" class="pr-0">
                      <button
                        mat-icon-button
                        color="accent"
                        class="btn-tbl-edit"
                        (click)="$event.stopPropagation()"
                        (click)="editCall(row)"
                      >
                        <mat-icon aria-label="Edit" class="col-white"
                          >edit</mat-icon
                        >
                      </button>
                      <button
                        mat-icon-button
                        color="accent"
                        class="btn-tbl-edit"
                        (click)="$event.stopPropagation()"
                        (click)="uploadCall(row)"
                      >
                        <mat-icon aria-label="Edit" class="col-white"
                          >upload</mat-icon
                        >
                      </button>
                      <button
                        mat-icon-button
                        color="accent"
                        class="btn-tbl-delete"
                        (click)="$event.stopPropagation()"
                        (click)="deleteItem(row)"
                      >
                        <mat-icon aria-label="Delete" class="col-white"
                          >delete</mat-icon
                        >
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    (click)="editCall(row)"
                    [style.cursor]="'pointer'"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
             
              <!-- Loading spinner -->
              <div *ngIf="classDatabases.isTblLoading" class="tbl-spinner">
                <mat-progress-spinner
                  color="primary"
                  [diameter]="40"
                  mode="indeterminate"
                >
                </mat-progress-spinner>
              </div>
              <!-- context menu start -->
              <div
                style="visibility: hidden; position: fixed"
                [style.left]="contextMenuPosition.x"
                [style.top]="contextMenuPosition.y"
                [matMenuTriggerFor]="contextMenu"
              ></div>
              <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                  <button mat-menu-item (click)="addNew()">
                    <mat-icon>add_box</mat-icon>
                    <span>Aggiungi Corso</span>
                  </button>
                  <button mat-menu-item (click)="editCall(item)">
                    <mat-icon>create</mat-icon>
                    <span>Modifica</span>
                  </button>
                  <button mat-menu-item (click)="deleteItem(item)">
                    <mat-icon>delete</mat-icon>
                    <span>Elimina</span>
                  </button>
                </ng-template>
              </mat-menu>

              <!-- context menu end -->
              <div
                *ngIf="!classDatabases.isTblLoading"
                class="no-results"
                [style.display]="
                  dataSource.renderedData.length == 0 ? '' : 'none'
                "
              >
                Nessun corso
              </div>
              <mat-paginator
                #paginator
                [length]="dataSource.filteredData.length"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
