import { LessonsService } from "./../../../../../core/service/lessons.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { MatCalendar } from "@angular/material/datepicker";
@Component({
  selector: "app-vacations",
  templateUrl: "./vacations.component.html",
  styleUrls: ["./vacations.component.sass"],
})
export class VacationsComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  vacationsDays = [];
  selected = new Date();
  loader = true;
  constructor(
    public dialogRef: MatDialogRef<VacationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: LessonsService,
    public zone: NgZone
  ) {}

  dateClass = (cellDate, view) => {
    // Only highligh dates inside the month view.

    const date = moment(cellDate).format("YYYY-MM-DD");

    for (const day of this.vacationsDays) {
      if (day == date) {
        return "vacationday";
      }
    }
    return "";
  };

  confirm() {
    this.service.setVacationsDays(this.vacationsDays).subscribe((data: any) => {
      if (data.success) {
        this.dialogRef.close(1);
      } else {
        this.dialogRef.close(-1);
      }
    });
  }

  selectedDate(event) {
    const day = moment(event).format("YYYY-MM-DD");
    const index = this.vacationsDays.indexOf(day);
    if (index != -1) {
      this.vacationsDays.splice(index, 1);
    } else {
      this.vacationsDays.push(day);
    }
    this.calendar.updateTodaysDate() ;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.service.getVacationsDays().subscribe((data: any) => {
      this.vacationsDays = data.data.response.days;
      this.loader = false;
    });
  }
}
