import { formatDate } from '@angular/common';
import { ClassModel } from './class.model';
export class Lesson {
  id: number;
  teacher: number;
  teachername : string ;
  subject: number;
  subjectname : string ;
  start : Date ;
  end :Date ;
  repeat : number ;
  classes : ClassModel[]  ;
  students : any[] ;
  invstudent : any[] ;
 
  constructor(lesson) {
    {
      this.id = lesson.id || 0;
      this.teacher = lesson.teacher || 0;
      this.teachername = lesson.teachername || '';
      this.subject = lesson.subject || 0  ;
      this.end = lesson.end || new Date()  ;
      this.subjectname = lesson.subjectname || ''  ;
      this.start = lesson.start || new Date()  ;
      this.classes = lesson.classes || []  ;
      this.students = lesson.students || [] ;
    }
  }

}

export class LessonRespose {
  success: boolean;
  data: Lesson[];
  message: string;
 
 
  

}
