import { formatDate } from '@angular/common';
import { Course } from 'src/app/core/models/course.model';
export class Subject {
  id: number;
  name: string;
  description: string;
  courses:any[];
 
  constructor(subject) {
    {
      this.id = subject.id || 0;
      this.name = subject.name || '';
      this.description = subject.description || '';
      this.courses = subject.courses || [];
     
    }
  }

}

export class SubjectRespose {
  success: boolean;
  data: Subject[];
  message: string;
 
 
  

}
