<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">Carica nuovo orario</div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content style="overflow-x: hidden">
    <h6>Carica file fet con orario</h6>
    <ngx-file-drop
      dropZoneLabel="Drop files here"
      (onFileDrop)="dropped($event)"
      (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div>
          <div style="width: 100%" *ngIf="tmp.progress > 0">
            <div
              [style.width]="tmp.progess + '%'"
              style="background-color: green; height: 20px"
            >
              {{ tmp.progess }} %
            </div>
          </div>
          <p>
            Trascina i file qua dentro il box oppure
            <button
              mat-raised-button
              type="button"
              (click)="openFileSelector()"
            >
              Carica file
            </button>
          </p>
          <ul style="display: inline">
            <li style="margin-bottom: 20px">
              Stai caricando
              <span *ngFor="let item of fileToLoads">{{ item.name }}, </span>
            </li>
          </ul>
        </div>
      </ng-template>
    </ngx-file-drop>
    <div class="row margin-top">

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>Valida da</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="from"
            [matDatepicker]="picker"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>Valida a</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="to"
            [matDatepicker]="pickerto"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerto"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerto></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mb-1">
    <button mat-flat-button color="warn" (click)="caricaSingoloFile()" [loading]="loading">
      Salva
    </button>
  </div>
</div>
