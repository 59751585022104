<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17">
            Registro di {{ student?.name }}
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content *ngIf="student">
    <div class="row px-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <p><b>Lezioni seguite {{ presenceLessons }}/ {{totalLessons}} </b></p>
          <mat-card *ngFor="let item of subjectLessons" class="mb-3">            
              <mat-card-title>{{item.name}}</mat-card-title>
              <mat-card-content>
                
                <div >
                    <b>Lezioni: {{item.lessons.presence}}/{{item.lessons.total}} </b>
                    <p *ngFor="let lesson of item.lessons.items">
                        Data: {{lesson.date | date:'dd/MM/YYYY HH:mm'}} Professore: {{lesson.teacher}}<br/>
                        <span class="note">{{lesson.note}}</span><br/>
                        <span class="note">{{lesson.student}}</span>
                    </p>
                </div>
                <div>
                    <b>Voti:</b> 
                    <span *ngFor="let voto of item.votes" class="voto">&nbsp;{{voto}}</span>
                    <i *ngIf="!item.votes.length" >&nbsp;Non ci sono voti assegnati</i>
                </div>
              </mat-card-content>
          </mat-card>          
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
        <div class="example-button-row">
          <button
            mat-raised-button
            color="warn"
            (click)="onNoClick()"
            tabindex="-1"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
