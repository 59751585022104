import { formatDate } from "@angular/common";

export type parentData = {
  name: string;
  email: string;
  password: string;
  phone: string;
};
export class Student {
  id: number;
  name: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  birthdate: string;
  class: number;
  classname: string;
  parent: parentData;
  parentcode: string;

  constructor(student) {
    {
      this.id = student.id || 0;
      this.name = student.name || "";
      this.email = student.email || "";
      this.password = "password";
      this.phone = student.phone || "";
      this.address = student.address || "";
      this.birthdate = student.birthdate || "";
      this.class = student.class || 0;
      this.classname = student.classname || "";
      this.parent = student.parentdata || {
        name: "",
        email: "",
        phone: "",
        password: "",
      };
      this.parentcode = student.parentcode || "";
    }
  }
}

export class StudentRespose {
  success: boolean;
  data: Student[];
  message: string;
}
