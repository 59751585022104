import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ListteachercsComponent} from './listteachers/listteachers.component'

const routes: Routes = [{
  path: '',
  redirectTo: 'main',
  pathMatch: 'full',
},
{
  path: 'main',
  component: ListteachercsComponent,
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeachersRoutingModule { }
