<div class="addContainer">
    <div class="modalHeader">
      <div class="editRowModal">
        <div class="modalHeader clearfix">
          <div class="modal-about">
            <div class="font-weight-bold p-t-5 font-17">
              Registro di {{ registerLesson?.subjectname }} del {{ getDate(lesson.start)}}
            </div>
          </div>
        </div>
      </div>
      <button
        mat-icon-button
        (click)="dialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div mat-dialog-content *ngIf="registerLesson">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <p><b>Professore {{registerLesson.teachername}}</b> : {{registerLesson.note === '' ? 'Non ci sono note' : registerLesson.note }}</p>

            <mat-card *ngFor="let item of registerLesson.classes">
                <mat-card-title>{{item.name}}</mat-card-title>
                <mat-card-content>
                  <p>{{item.note}}</p>
                  <div *ngFor="let student of item.students">
                      <b>{{student.name}} <i>(Presente {{student.presence.length?'Si':'No'}})</i></b> : {{student.note!== ''?student.note:'Non ci sono note'}}<br>
                      <i>Voto: {{student.vote?student.vote:'Non ci sono voti'}}</i>

                  </div>

                </mat-card-content>
            </mat-card>

        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="example-button-row">
            <button
              mat-raised-button
              color="warn"
              (click)="onNoClick()"
              tabindex="-1"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
