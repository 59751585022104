import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { Teacher } from '../../../../../core/models/teacher.model';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { TeacherService } from '../../../../../core/service/teacher.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }]
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  libraryForm: FormGroup;
  library: Teacher;
  loading = false ;
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public libraryService: TeacherService,
    private fb: FormBuilder
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === 'edit') {
      console.log(data.teacher);
      this.dialogTitle = data.teacher.name;
      this.library = data.teacher;
    } else {
      this.dialogTitle = 'Nuovo Insegnante';
      this.library = new Teacher({});
    }
    this.libraryForm = this.createContactForm();
  }
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : this.formControl.hasError('email')
      ? 'Not a valid email'
      : '';
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      id: [this.library.id],
      name: [this.library.name, [Validators.required]],
      email: [this.library.email, [Validators.required,Validators.email]],
      // password: [this.library.password || 'password', [Validators.required]],
    });
  }
  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {
    this.loading = false ;
    this.libraryService.addTeacher(this.libraryForm.getRawValue()).subscribe(data=>{
      this.loading = true ;
      if(data.success){
        this.dialogRef.close(1);
      }
      else{
        this.dialogRef.close(-1);
      }
    },
    (err: HttpErrorResponse) => {
      this.dialogRef.close(-1);
    });
  }
}
