import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ClassModel, ClassRespose } from "../models/class.model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class ClassesService {
  private readonly API_URL = environment.apiUrl + "/api/classes";
  isTblLoading = true;
  dataChange: BehaviorSubject<ClassModel[]> = new BehaviorSubject<ClassModel[]>(
    []
  );
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}

  get data(): ClassModel[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllClasses(): void {
    this.httpClient.get<ClassRespose>(this.API_URL + "/all").subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  getTeacherClasses() {
    return this.httpClient.get<any>(this.API_URL + "/teacher");
  }

  getClass(id) {
    return this.httpClient.get(this.API_URL + "/class/" + id);
  }

  get Classes() {
    return this.httpClient.get<ClassRespose>(this.API_URL + "/all");
  }

  addClass(classmodel: ClassModel) {
    this.dialogData = classmodel;
    if (classmodel.id == 0) delete classmodel.id;
    return this.httpClient.post(this.API_URL + "/save", classmodel).pipe(
      map((data: any) => {
        if (data.success) this.dialogData = data.data;
        return data;
      })
    );
  }

  deleteClass(id: number) {
    console.log(id);

    return this.httpClient.delete(this.API_URL + "/delete/" + id).pipe(
      map((data: any) => {
        console.log(data);
        return data;
      })
    );
  }

  assignStudent(classid, student) {
    return this.httpClient
      .post(this.API_URL + "/student", { class: classid, student: student })
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  deleteStudent(classid, student) {
    return this.httpClient
      .delete(this.API_URL + `${classid}/student/${student}`)
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  assignLesson(classid, lesson) {
    return this.httpClient
      .post(this.API_URL + "/student", { class: classid, lesson: lesson })
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  deleteLesson(classid, lesson) {
    return this.httpClient
      .delete(this.API_URL + `${classid}/lesson/${lesson}`)
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  saveFile(file, teacher, subject, c_id, student = "") {
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("teacher", teacher);
    formData.append("subject", subject);
    formData.append("class", c_id);

    return this.httpClient.post(this.API_URL + "/upload", formData, {
      reportProgress: true,
      observe: "events",
    });
  }
}
